import React from "react";
import ReactDOM from "react-dom/client";
import "antd/dist/antd.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import ActionCable from "actioncable";
import appConfig from "./config";

const DOMAIN = appConfig.appDomain;
const userToken = window.localStorage.getItem("userToken");

const cableApp = {};
if (userToken) {
  cableApp.cable = ActionCable.createConsumer(
    `${DOMAIN}/cable?token=${userToken.replaceAll('"', "")}`
  );
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <App cable={cableApp.cable} />
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
