const env = process.env.NODE_ENV || "development";

export const appConfig = {
  appDomain: 'https://api.thebreakfast.app',
  // appDomain: {
  //   development: "http://localhost:3000",
  //   staging: "https://staging-api.thebreakfast.app",
  //   production: "https://api.thebreakfast.app",
  // }[env],
};

export default appConfig;
