import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

const Labels = {
  lists: 'Places',
  moderation: 'Moderation',
  episodes: 'Episodes',
  talks: 'Talks',
  email_templates: 'Email Templates',
  push_templates: 'Push Templates',
  communities: 'directory',
  sunday_serendipities: 'Sunday Serendipities',
}

const Breadcrumbs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const pathnames = pathname.split("/").filter(Boolean).filter(v => v !== 'dashboard');

  return (
    <div className="breadcrumbs">
      <div className="breadcrumb">
        {pathnames.length ? (
          <Link className="caption red upper-case" to="/dashboard">
            Team
          </Link>
        ) : (
          <div className="caption upper-case">Team</div>
        )}
      </div>

      {pathnames.map((name, index) => {
        const routeTo = `/dashboard/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return (
          <div className="breadcrumb" key={name}>
            {isLast ? (
              <div className="caption upper-case">
                {Labels[name]}
              </div>
            ) : (
              <Link key={name} to={routeTo} className="caption red upper-case">
                {Labels[name]}
              </Link>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
